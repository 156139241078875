
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































@mixin flexible-materials-list-desktop {
  display: flex;
  justify-content: center;
  align-items: baseline;

  @include mq(xl) {
    display: inline-flex;

    & + & {
      margin-top: 0;
      margin-left: $spacing * 2;
    }
  }
}

@mixin flexible-materials-item {
  margin: 0;

  &:not(:first-child) {
    margin: 0 0 0 $spacing * -1;
  }
}

.flexible-materials {
  @extend %text-center;
}

.flexible-materials__text {
  margin: $spacing * 0.5 auto $spacing * 1.5;

  @include mq(l) {
    max-width: 40em;
  }
}

.flexible-materials__list {
  @extend %list-nostyle;

  margin-top: $spacing;

  & + & {
    margin-top: $spacing * 2;
  }

  // When list contains 2 items, keep same display behavior on small (xxs+) and large screen sizes
  &.items-count-2 {
    @include mq(xxs) {
      @include flexible-materials-list-desktop;
    }
  }

  @include mq(m) {
    @include flexible-materials-list-desktop;
  }
}

.flexible-materials__item {
  width: 15rem;
  margin: 0 auto;

  & + & {
    margin-top: $spacing;
  }

  .items-count-2 & {
    @include mq(xxs) {
      @include flexible-materials-item;
    }
  }

  @include mq(m) {
    @include flexible-materials-item;
  }
}

.flexible-materials__item__picture {
  position: relative;
  overflow: hidden;
  width: 15rem;
  height: 15rem;
  margin: auto;
  background: $c-white;
  border: 1px solid $c-light-grey;
  border-radius: 50%;
  box-shadow: $card-shadow;

  img {
    @include image-fit;
  }
}

.flexible-materials__item__label {
  @extend %fw-bold;

  padding: 0 $spacing;
  font-size: 1.6rem;
  line-height: 1.9rem;
}
